import {Platform} from 'react-native'

import {
  darkPalette,
  lightPalette,
  dimPalette,
  shinyPalette,
  illuminatePalette,
  lanticaPalette,
  hokagoPalette,
  alstroemeriaPalette,
  straylightPalette,
  noctchillPalette,
  shhisPalette,
  cometikPalette,
} from '#/alf/themes'
import {colors} from './styles'
import type {Theme} from './ThemeContext'

export const defaultTheme: Theme = {
  colorScheme: 'light',
  palette: {
    default: {
      background: lightPalette.white,
      backgroundLight: lightPalette.contrast_25,
      text: lightPalette.black,
      textLight: lightPalette.contrast_700,
      textInverted: lightPalette.white,
      link: lightPalette.primary_500,
      border: lightPalette.contrast_100,
      borderDark: lightPalette.contrast_200,
      icon: lightPalette.contrast_500,

      // non-standard
      textVeryLight: lightPalette.contrast_400,
      replyLine: lightPalette.contrast_100,
      replyLineDot: lightPalette.contrast_200,
      unreadNotifBg: lightPalette.primary_25,
      unreadNotifBorder: lightPalette.primary_100,
      postCtrl: lightPalette.contrast_500,
      brandText: lightPalette.primary_500,
      emptyStateIcon: lightPalette.contrast_300,
      borderLinkHover: lightPalette.contrast_300,
    },
    primary: {
      background: colors.blue3,
      backgroundLight: colors.blue2,
      text: colors.white,
      textLight: colors.blue0,
      textInverted: colors.blue3,
      link: colors.blue0,
      border: colors.blue4,
      borderDark: colors.blue5,
      icon: colors.blue4,
    },
    secondary: {
      background: colors.green3,
      backgroundLight: colors.green2,
      text: colors.white,
      textLight: colors.green1,
      textInverted: colors.green4,
      link: colors.green1,
      border: colors.green4,
      borderDark: colors.green5,
      icon: colors.green4,
    },
    inverted: {
      background: darkPalette.black,
      backgroundLight: darkPalette.contrast_50,
      text: darkPalette.white,
      textLight: darkPalette.contrast_700,
      textInverted: darkPalette.black,
      link: darkPalette.primary_500,
      border: darkPalette.contrast_100,
      borderDark: darkPalette.contrast_200,
      icon: darkPalette.contrast_500,
    },
    error: {
      background: colors.red3,
      backgroundLight: colors.red2,
      text: colors.white,
      textLight: colors.red1,
      textInverted: colors.red3,
      link: colors.red1,
      border: colors.red4,
      borderDark: colors.red5,
      icon: colors.red4,
    },
  },
  shapes: {
    button: {
      // TODO
    },
    bigButton: {
      // TODO
    },
    smallButton: {
      // TODO
    },
  },
  typography: {
    '2xl-thin': {
      fontSize: 18,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    '2xl': {
      fontSize: 18,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    '2xl-medium': {
      fontSize: 18,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    '2xl-bold': {
      fontSize: 18,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    '2xl-heavy': {
      fontSize: 18,
      letterSpacing: 0.25,
      fontWeight: '800',
    },
    'xl-thin': {
      fontSize: 17,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    xl: {
      fontSize: 17,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    'xl-medium': {
      fontSize: 17,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'xl-bold': {
      fontSize: 17,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    'xl-heavy': {
      fontSize: 17,
      letterSpacing: 0.25,
      fontWeight: '800',
    },
    'lg-thin': {
      fontSize: 16,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    lg: {
      fontSize: 16,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    'lg-medium': {
      fontSize: 16,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'lg-bold': {
      fontSize: 16,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    'lg-heavy': {
      fontSize: 16,
      letterSpacing: 0.25,
      fontWeight: '800',
    },
    'md-thin': {
      fontSize: 15,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    md: {
      fontSize: 15,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    'md-medium': {
      fontSize: 15,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'md-bold': {
      fontSize: 15,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    'md-heavy': {
      fontSize: 15,
      letterSpacing: 0.25,
      fontWeight: '800',
    },
    'sm-thin': {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    sm: {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    'sm-medium': {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'sm-bold': {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    'sm-heavy': {
      fontSize: 14,
      letterSpacing: 0.25,
      fontWeight: '800',
    },
    'xs-thin': {
      fontSize: 13,
      letterSpacing: 0.25,
      fontWeight: '300',
    },
    xs: {
      fontSize: 13,
      letterSpacing: 0.25,
      fontWeight: '400',
    },
    'xs-medium': {
      fontSize: 13,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'xs-bold': {
      fontSize: 13,
      letterSpacing: 0.25,
      fontWeight: '700',
    },
    'xs-heavy': {
      fontSize: 13,
      letterSpacing: 0.25,
      fontWeight: '800',
    },

    'title-2xl': {
      fontSize: 34,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'title-xl': {
      fontSize: 28,
      letterSpacing: 0.25,
      fontWeight: '500',
    },
    'title-lg': {
      fontSize: 22,
      fontWeight: '500',
    },
    title: {
      fontWeight: '500',
      fontSize: 20,
      letterSpacing: 0.15,
    },
    'title-sm': {
      fontWeight: 'bold',
      fontSize: 17,
      letterSpacing: 0.15,
    },
    'post-text': {
      fontSize: 16,
      letterSpacing: 0.2,
      fontWeight: '400',
    },
    'post-text-lg': {
      fontSize: 20,
      letterSpacing: 0.2,
      fontWeight: '400',
    },
    'button-lg': {
      fontWeight: '500',
      fontSize: 18,
      letterSpacing: 0.5,
    },
    button: {
      fontWeight: '500',
      fontSize: 14,
      letterSpacing: 0.5,
    },
    mono: {
      fontSize: 14,
      fontFamily: Platform.OS === 'android' ? 'monospace' : 'Courier New',
    },
  },
}

export const darkTheme: Theme = {
  ...defaultTheme,
  colorScheme: 'dark',
  palette: {
    ...defaultTheme.palette,
    default: {
      background: darkPalette.black,
      backgroundLight: darkPalette.contrast_25,
      text: darkPalette.white,
      textLight: darkPalette.contrast_600,
      textInverted: darkPalette.black,
      link: darkPalette.primary_500,
      border: darkPalette.contrast_100,
      borderDark: darkPalette.contrast_200,
      icon: darkPalette.contrast_500,

      // non-standard
      textVeryLight: darkPalette.contrast_400,
      replyLine: darkPalette.contrast_200,
      replyLineDot: darkPalette.contrast_200,
      unreadNotifBg: darkPalette.primary_25,
      unreadNotifBorder: darkPalette.primary_100,
      postCtrl: darkPalette.contrast_500,
      brandText: darkPalette.primary_500,
      emptyStateIcon: darkPalette.contrast_300,
      borderLinkHover: darkPalette.contrast_300,
    },
    primary: {
      ...defaultTheme.palette.primary,
      textInverted: colors.blue2,
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      textInverted: colors.green2,
    },
    inverted: {
      background: lightPalette.white,
      backgroundLight: lightPalette.contrast_50,
      text: lightPalette.black,
      textLight: lightPalette.contrast_700,
      textInverted: lightPalette.white,
      link: lightPalette.primary_500,
      border: lightPalette.contrast_100,
      borderDark: lightPalette.contrast_200,
      icon: lightPalette.contrast_500,
    },
  },
}

export const dimTheme: Theme = {
  ...darkTheme,
  palette: {
    ...darkTheme.palette,
    default: {
      ...darkTheme.palette.default,
      background: dimPalette.black,
      backgroundLight: dimPalette.contrast_25,
      text: dimPalette.white,
      textLight: dimPalette.contrast_700,
      textInverted: dimPalette.black,
      link: dimPalette.primary_500,
      border: dimPalette.contrast_100,
      borderDark: dimPalette.contrast_200,
      icon: dimPalette.contrast_500,

      // non-standard
      textVeryLight: dimPalette.contrast_400,
      replyLine: dimPalette.contrast_200,
      replyLineDot: dimPalette.contrast_200,
      unreadNotifBg: dimPalette.primary_25,
      unreadNotifBorder: dimPalette.primary_100,
      postCtrl: dimPalette.contrast_500,
      brandText: dimPalette.primary_500,
      emptyStateIcon: dimPalette.contrast_300,
      borderLinkHover: dimPalette.contrast_300,
    },
  },
}

export const shinyTheme: Theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    default: {
      background: shinyPalette.white,
      backgroundLight: shinyPalette.contrast_25,
      text: shinyPalette.black,
      textLight: shinyPalette.contrast_700,
      textInverted: shinyPalette.white,
      link: shinyPalette.primary_500,
      border: shinyPalette.contrast_100,
      borderDark: shinyPalette.contrast_200,
      icon: shinyPalette.contrast_500,

      // non-standard
      textVeryLight: shinyPalette.contrast_400,
      replyLine: shinyPalette.contrast_100,
      replyLineDot: shinyPalette.contrast_200,
      unreadNotifBg: shinyPalette.primary_25,
      unreadNotifBorder: shinyPalette.primary_100,
      postCtrl: shinyPalette.contrast_500,
      brandText: shinyPalette.primary_500,
      emptyStateIcon: shinyPalette.contrast_300,
      borderLinkHover: shinyPalette.contrast_300,
    },
  },
}

export const illuminateTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: illuminatePalette.white,
      backgroundLight: illuminatePalette.contrast_25,
      text: illuminatePalette.black,
      textLight: illuminatePalette.contrast_700,
      textInverted: illuminatePalette.white,
      link: illuminatePalette.primary_500,
      border: illuminatePalette.contrast_100,
      borderDark: illuminatePalette.contrast_200,
      icon: illuminatePalette.contrast_500,

      // non-standard
      textVeryLight: illuminatePalette.contrast_400,
      replyLine: illuminatePalette.contrast_100,
      replyLineDot: illuminatePalette.contrast_200,
      unreadNotifBg: illuminatePalette.primary_25,
      unreadNotifBorder: illuminatePalette.primary_100,
      postCtrl: illuminatePalette.contrast_500,
      brandText: illuminatePalette.primary_500,
      emptyStateIcon: illuminatePalette.contrast_300,
      borderLinkHover: illuminatePalette.contrast_300,
    },
  },
}

export const lanticaTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: lanticaPalette.white,
      backgroundLight: lanticaPalette.contrast_25,
      text: lanticaPalette.black,
      textLight: lanticaPalette.contrast_700,
      textInverted: lanticaPalette.white,
      link: lanticaPalette.primary_500,
      border: lanticaPalette.contrast_100,
      borderDark: lanticaPalette.contrast_200,
      icon: lanticaPalette.contrast_500,

      // non-standard
      textVeryLight: lanticaPalette.contrast_400,
      replyLine: lanticaPalette.contrast_100,
      replyLineDot: lanticaPalette.contrast_200,
      unreadNotifBg: lanticaPalette.primary_25,
      unreadNotifBorder: lanticaPalette.primary_100,
      postCtrl: lanticaPalette.contrast_500,
      brandText: lanticaPalette.primary_500,
      emptyStateIcon: lanticaPalette.contrast_300,
      borderLinkHover: lanticaPalette.contrast_300,
    },
  },
}

export const hokagoTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: hokagoPalette.white,
      backgroundLight: hokagoPalette.contrast_25,
      text: hokagoPalette.black,
      textLight: hokagoPalette.contrast_700,
      textInverted: hokagoPalette.white,
      link: hokagoPalette.primary_500,
      border: hokagoPalette.contrast_100,
      borderDark: hokagoPalette.contrast_200,
      icon: hokagoPalette.contrast_500,

      // non-standard
      textVeryLight: hokagoPalette.contrast_400,
      replyLine: hokagoPalette.contrast_100,
      replyLineDot: hokagoPalette.contrast_200,
      unreadNotifBg: hokagoPalette.primary_25,
      unreadNotifBorder: hokagoPalette.primary_100,
      postCtrl: hokagoPalette.contrast_500,
      brandText: hokagoPalette.primary_500,
      emptyStateIcon: hokagoPalette.contrast_300,
      borderLinkHover: hokagoPalette.contrast_300,
    },
  },
}

export const alstroemeriaTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: alstroemeriaPalette.white,
      backgroundLight: alstroemeriaPalette.contrast_25,
      text: alstroemeriaPalette.black,
      textLight: alstroemeriaPalette.contrast_700,
      textInverted: alstroemeriaPalette.white,
      link: alstroemeriaPalette.primary_500,
      border: alstroemeriaPalette.contrast_100,
      borderDark: alstroemeriaPalette.contrast_200,
      icon: alstroemeriaPalette.contrast_500,

      // non-standard
      textVeryLight: alstroemeriaPalette.contrast_400,
      replyLine: alstroemeriaPalette.contrast_100,
      replyLineDot: alstroemeriaPalette.contrast_200,
      unreadNotifBg: alstroemeriaPalette.primary_25,
      unreadNotifBorder: alstroemeriaPalette.primary_100,
      postCtrl: alstroemeriaPalette.contrast_500,
      brandText: alstroemeriaPalette.primary_500,
      emptyStateIcon: alstroemeriaPalette.contrast_300,
      borderLinkHover: alstroemeriaPalette.contrast_300,
    },
  },
}

export const straylightTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: straylightPalette.white,
      backgroundLight: straylightPalette.contrast_25,
      text: straylightPalette.black,
      textLight: straylightPalette.contrast_700,
      textInverted: straylightPalette.white,
      link: straylightPalette.primary_500,
      border: straylightPalette.contrast_100,
      borderDark: straylightPalette.contrast_200,
      icon: straylightPalette.contrast_500,

      // non-standard
      textVeryLight: straylightPalette.contrast_400,
      replyLine: straylightPalette.contrast_100,
      replyLineDot: straylightPalette.contrast_200,
      unreadNotifBg: straylightPalette.primary_25,
      unreadNotifBorder: straylightPalette.primary_100,
      postCtrl: straylightPalette.contrast_500,
      brandText: straylightPalette.primary_500,
      emptyStateIcon: straylightPalette.contrast_300,
      borderLinkHover: straylightPalette.contrast_300,
    },
  },
}

export const noctchillTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: noctchillPalette.white,
      backgroundLight: noctchillPalette.contrast_25,
      text: noctchillPalette.black,
      textLight: noctchillPalette.contrast_700,
      textInverted: noctchillPalette.white,
      link: noctchillPalette.primary_500,
      border: noctchillPalette.contrast_100,
      borderDark: noctchillPalette.contrast_200,
      icon: noctchillPalette.contrast_500,

      // non-standard
      textVeryLight: noctchillPalette.contrast_400,
      replyLine: noctchillPalette.contrast_100,
      replyLineDot: noctchillPalette.contrast_200,
      unreadNotifBg: noctchillPalette.primary_25,
      unreadNotifBorder: noctchillPalette.primary_100,
      postCtrl: noctchillPalette.contrast_500,
      brandText: noctchillPalette.primary_500,
      emptyStateIcon: noctchillPalette.contrast_300,
      borderLinkHover: noctchillPalette.contrast_300,
    },
  },
}

export const shhisTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: shhisPalette.white,
      backgroundLight: shhisPalette.contrast_25,
      text: shhisPalette.black,
      textLight: shhisPalette.contrast_700,
      textInverted: shhisPalette.white,
      link: shhisPalette.primary_500,
      border: shhisPalette.contrast_100,
      borderDark: shhisPalette.contrast_200,
      icon: shhisPalette.contrast_500,

      // non-standard
      textVeryLight: shhisPalette.contrast_400,
      replyLine: shhisPalette.contrast_100,
      replyLineDot: shhisPalette.contrast_200,
      unreadNotifBg: shhisPalette.primary_25,
      unreadNotifBorder: shhisPalette.primary_100,
      postCtrl: shhisPalette.contrast_500,
      brandText: shhisPalette.primary_500,
      emptyStateIcon: shhisPalette.contrast_300,
      borderLinkHover: shhisPalette.contrast_300,
    },
  },
}

export const cometikTheme: Theme = {
  ...shinyTheme,
  palette: {
    ...shinyTheme.palette,
    default: {
      background: cometikPalette.white,
      backgroundLight: cometikPalette.contrast_25,
      text: cometikPalette.black,
      textLight: cometikPalette.contrast_700,
      textInverted: cometikPalette.white,
      link: cometikPalette.primary_500,
      border: cometikPalette.contrast_100,
      borderDark: cometikPalette.contrast_200,
      icon: cometikPalette.contrast_500,

      // non-standard
      textVeryLight: cometikPalette.contrast_400,
      replyLine: cometikPalette.contrast_100,
      replyLineDot: cometikPalette.contrast_200,
      unreadNotifBg: cometikPalette.primary_25,
      unreadNotifBorder: cometikPalette.primary_100,
      postCtrl: cometikPalette.contrast_500,
      brandText: cometikPalette.primary_500,
      emptyStateIcon: cometikPalette.contrast_300,
      borderLinkHover: cometikPalette.contrast_300,
    },
  },
}
