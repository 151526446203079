export const fontStyles = [
  {style: 'Bold', text: '𝐬𝐡𝐢𝐧𝐲'},
  {style: 'Italic', text: '𝑠ℎ𝑖𝑛𝑦'},
  {style: 'BoldItalic', text: '𝒔𝒉𝒊𝒏𝒚'},
  {style: 'SansSerifBold', text: '𝘀𝗵𝗶𝗻𝘆'},
  {style: 'BoldScript', text: '𝓼𝓱𝓲𝓷𝔂'},
  {style: 'Monospace', text: '𝚜𝚑𝚒𝚗𝚢'},
  {style: 'Fraktor', text: '𝔰𝔥𝔦𝔫𝔶'},
  {style: 'DoubleStruck', text: '𝕤𝕙𝕚𝕟𝕪'},
]

const UTF16_SURROGATE_PAIR_HIGH_BASE = 0xd800
const UTF16_SURROGATE_PAIR_HIGH_END = 0xdbff
const UTF16_SURROGATE_PAIR_LOW_BASE = 0xdc00
const UTF16_SURROGATE_PAIR_LOW_END = 0xdfff

const UTF32_SURROGATE_PAIR_BASE = UTF16_SURROGATE_PAIR_HIGH_BASE
const UTF32_SURROGATE_PAIR_END = UTF16_SURROGATE_PAIR_LOW_END

const UTF16_ZERO_WIDTH_NON_JOINER = 0x200c

const KanaType = {
  KATA: Symbol(),
  HIRA: Symbol(),
  NARROW: Symbol(),
  CIRCLED: Symbol(),
  NOT_KANA: Symbol(),
  NA: Symbol(),
}

const DakuType = {
  PLAIN: Symbol(),
  DAKU: Symbol(),
  HANDAKU: Symbol(),
  NA: Symbol(),
}

const KanaSize = {
  LARGE: Symbol(),
  SMALL: Symbol(),
  NA: Symbol(),
}

class KanaSet {
  constructor(
    hiraPlain,
    hiraDaku,
    hiraHandaku,
    kataPlain,
    kataDaku,
    kataHandaku,
    narrow,
    circled,
    isSmallKana,
    sizePair,
    dakuable,
  ) {
    this.hiraPlain = hiraPlain

    this.hiraDaku = hiraDaku

    this.hiraHandaku = hiraHandaku

    this.kataPlain = kataPlain

    this.kataDaku = kataDaku

    this.kataHandaku = kataHandaku

    this.circled = circled

    this.narrow = narrow

    this.isSmallKana = isSmallKana

    this.sizePair = sizePair

    this.dakuable = dakuable
  }

  tryGetNativeChar(ktype = KanaType.HIRA, dtype = DakuType.PLAIN) {
    if (!this.dakuable) dtype = DakuType.PLAIN

    if (ktype == KanaType.HIRA && dtype == DakuType.PLAIN) return this.hiraPlain
    else if (ktype == KanaType.HIRA && dtype == DakuType.DAKU)
      return this.hiraDaku
    else if (ktype == KanaType.HIRA && dtype == DakuType.HANDAKU)
      return this.hiraHandaku
    else if (ktype == KanaType.KATA && dtype == DakuType.PLAIN)
      return this.kataPlain
    else if (ktype == KanaType.KATA && dtype == DakuType.DAKU)
      return this.kataDaku
    else if (ktype == KanaType.KATA && dtype == DakuType.HANDAKU)
      return this.kataHandaku
    else if (ktype == KanaType.NARROW && dtype == DakuType.PLAIN)
      return this.narrow
    else if (ktype == KanaType.CIRCLED && dtype == DakuType.PLAIN)
      return this.circled
    return null
  }

  encode(ktype = KanaType.HIRA, dtype = DakuType.PLAIN) {
    if (!this.dakuable) dtype = DakuType.PLAIN

    if (ktype == KanaType.HIRA && !this.hiraPlain) {
      if (this.kataPlain) ktype = KanaType.KATA
      else if (this.narrow) ktype = KanaType.NARROW
      else if (this.circled) ktype = KanaType.CIRCLED
      else return '?'
    } else if (ktype == KanaType.KATA && !this.kataPlain) {
      if (this.hiraPlain) ktype = KanaType.HIRA
      else if (this.narrow) ktype = KanaType.NARROW
      else if (this.circled) ktype = KanaType.CIRCLED
      else return '?'
    } else if (ktype == KanaType.NARROW && !this.narrow) {
      if (this.kataPlain) ktype = KanaType.KATA
      else if (this.hiraPlain) ktype = KanaType.HIRA
      else if (this.circled) ktype = KanaType.CIRCLED
      else return '?'
    } else if (ktype == KanaType.CIRCLED && !this.circled) {
      if (this.kataPlain) ktype = KanaType.KATA
      else if (this.hiraPlain) ktype = KanaType.HIRA
      else if (this.narrow) ktype = KanaType.NARROW
      else return '?'
    }

    var c = this.tryGetNativeChar(ktype, dtype)
    if (c) return c

    if (ktype == KanaType.HIRA || ktype == KanaType.KATA) {
      if (dtype == DakuType.DAKU) return this.tryGetNativeChar(ktype) + '\u3099'
      if (dtype == DakuType.HANDAKU)
        return this.tryGetNativeChar(ktype) + '\u309a'
    } else if (ktype == KanaType.NARROW) {
      if (dtype == DakuType.DAKU) return this.narrow + 'ﾞ'
      if (dtype == DakuType.HANDAKU) return this.narrow + 'ﾟ'
    } else if (ktype == KanaType.CIRCLED) {
      if (dtype == DakuType.DAKU) return this.circled + 'ﾞ'
      if (dtype == DakuType.HANDAKU) return this.circled + 'ﾟ'
    }

    return '?'
  }
}

const KANA_SET_LIST = [
  new KanaSet('あ', null, null, 'ア', null, null, 'ｱ', '㋐', false, 'ァ', true),
  new KanaSet('い', null, null, 'イ', null, null, 'ｲ', '㋑', false, 'ィ', true),
  new KanaSet('う', null, null, 'ウ', 'ヴ', null, 'ｳ', '㋒', false, 'ゥ', true),
  new KanaSet('え', null, null, 'エ', null, null, 'ｴ', '㋓', false, 'ェ', true),
  new KanaSet('お', null, null, 'オ', null, null, 'ｵ', '㋔', false, 'ォ', true),
  new KanaSet('か', 'が', null, 'カ', 'ガ', null, 'ｶ', '㋕', false, 'ヵ', true),
  new KanaSet('き', 'ぎ', null, 'キ', 'ギ', null, 'ｷ', '㋖', false, null, true),
  new KanaSet('く', 'ぐ', null, 'ク', 'グ', null, 'ｸ', '㋗', false, 'ㇰ', true),
  new KanaSet('け', 'げ', null, 'ケ', 'ゲ', null, 'ｹ', '㋘', false, 'ヶ', true),
  new KanaSet('こ', 'ご', null, 'コ', 'ゴ', null, 'ｺ', '㋙', false, null, true),
  new KanaSet('さ', 'ざ', null, 'サ', 'ザ', null, 'ｻ', '㋚', false, null, true),
  new KanaSet('し', 'じ', null, 'シ', 'ジ', null, 'ｼ', '㋛', false, 'ㇱ', true),
  new KanaSet('す', 'ず', null, 'ス', 'ズ', null, 'ｽ', '㋜', false, 'ㇲ', true),
  new KanaSet('せ', 'ぜ', null, 'セ', 'ゼ', null, 'ｾ', '㋝', false, null, true),
  new KanaSet('そ', 'ぞ', null, 'ソ', 'ゾ', null, 'ｿ', '㋞', false, null, true),
  new KanaSet('た', 'だ', null, 'タ', 'ダ', null, 'ﾀ', '㋟', false, null, true),
  new KanaSet('ち', 'ぢ', null, 'チ', 'ヂ', null, 'ﾁ', '㋠', false, null, true),
  new KanaSet('つ', 'づ', null, 'ツ', 'ヅ', null, 'ﾂ', '㋡', false, 'ッ', true),
  new KanaSet('て', 'で', null, 'テ', 'デ', null, 'ﾃ', '㋢', false, null, true),
  new KanaSet('と', 'ど', null, 'ト', 'ド', null, 'ﾄ', '㋣', false, 'ㇳ', true),
  new KanaSet('な', null, null, 'ナ', null, null, 'ﾅ', '㋤', false, null, true),
  new KanaSet('に', null, null, 'ニ', null, null, 'ﾆ', '㋥', false, null, true),
  new KanaSet('ぬ', null, null, 'ヌ', null, null, 'ﾇ', '㋦', false, null, true),
  new KanaSet('ね', null, null, 'ネ', null, null, 'ﾈ', '㋧', false, 'ㇴ', true),
  new KanaSet('の', null, null, 'ノ', null, null, 'ﾉ', '㋨', false, null, true),
  new KanaSet('は', 'ば', 'ぱ', 'ハ', 'バ', 'パ', 'ﾊ', '㋩', false, 'ㇵ', true),
  new KanaSet('ひ', 'び', 'ぴ', 'ヒ', 'ビ', 'ピ', 'ﾋ', '㋪', false, 'ㇶ', true),
  new KanaSet('ふ', 'ぶ', 'ぷ', 'フ', 'ブ', 'プ', 'ﾌ', '㋫', false, 'ㇷ', true),
  new KanaSet('へ', 'べ', 'ぺ', 'ヘ', 'ベ', 'ペ', 'ﾍ', '㋬', false, 'ㇸ', true),
  new KanaSet('ほ', 'ぼ', 'ぽ', 'ホ', 'ボ', 'ポ', 'ﾎ', '㋭', false, 'ㇹ', true),
  new KanaSet('ま', null, null, 'マ', null, null, 'ﾏ', '㋮', false, null, true),
  new KanaSet('み', null, null, 'ミ', null, null, 'ﾐ', '㋯', false, null, true),
  new KanaSet('む', null, null, 'ム', null, null, 'ﾑ', '㋰', false, 'ㇺ', true),
  new KanaSet('め', null, null, 'メ', null, null, 'ﾒ', '㋱', false, null, true),
  new KanaSet('も', null, null, 'モ', null, null, 'ﾓ', '㋲', false, null, true),
  new KanaSet('や', null, null, 'ヤ', null, null, 'ﾔ', '㋳', false, 'ャ', true),
  new KanaSet('ゆ', null, null, 'ユ', null, null, 'ﾕ', '㋴', false, 'ュ', true),
  new KanaSet('よ', null, null, 'ヨ', null, null, 'ﾖ', '㋵', false, 'ョ', true),
  new KanaSet('ら', null, null, 'ラ', null, null, 'ﾗ', '㋶', false, 'ㇻ', true),
  new KanaSet('り', null, null, 'リ', null, null, 'ﾘ', '㋷', false, 'ㇼ', true),
  new KanaSet('る', null, null, 'ル', null, null, 'ﾙ', '㋸', false, 'ㇽ', true),
  new KanaSet('れ', null, null, 'レ', null, null, 'ﾚ', '㋹', false, 'ㇾ', true),
  new KanaSet('ろ', null, null, 'ロ', null, null, 'ﾛ', '㋺', false, 'ㇿ', true),
  new KanaSet('わ', null, null, 'ワ', 'ヷ', null, 'ﾜ', '㋻', false, 'ヮ', true),
  new KanaSet(
    'ゐ',
    null,
    null,
    'ヰ',
    null,
    null,
    null,
    '㋼',
    false,
    null,
    true,
  ),
  new KanaSet('を', null, null, 'ヲ', 'ヺ', null, 'ｦ', '㋾', false, null, true),
  new KanaSet(
    'ゑ',
    null,
    null,
    'ヱ',
    null,
    null,
    null,
    '㋽',
    false,
    null,
    true,
  ),
  new KanaSet('ん', null, null, 'ン', null, null, 'ﾝ', null, false, null, true),
  new KanaSet('ぁ', null, null, 'ァ', null, null, 'ｧ', null, true, 'あ', false),
  new KanaSet('ぃ', null, null, 'ィ', null, null, 'ｨ', null, true, 'い', false),
  new KanaSet('ぅ', null, null, 'ゥ', null, null, 'ｩ', null, true, 'う', false),
  new KanaSet('ぇ', null, null, 'ェ', null, null, 'ｪ', null, true, 'え', false),
  new KanaSet('ぉ', null, null, 'ォ', null, null, 'ｫ', null, true, 'お', false),
  new KanaSet(null, null, null, 'ヵ', null, null, null, null, true, 'か', true),
  new KanaSet(null, null, null, 'ㇰ', null, null, null, null, true, 'く', true),
  new KanaSet(null, null, null, 'ヶ', null, null, null, null, true, 'け', true),
  new KanaSet(null, null, null, 'ㇱ', null, null, null, null, true, 'し', true),
  new KanaSet(null, null, null, 'ㇲ', null, null, null, null, true, 'す', true),
  new KanaSet('っ', null, null, 'ッ', null, null, 'ｯ', null, true, 'つ', false),
  new KanaSet(null, null, null, 'ㇳ', null, null, null, null, true, 'と', true),
  new KanaSet(null, null, null, 'ㇴ', null, null, null, null, true, 'ぬ', true),
  new KanaSet(null, null, null, 'ㇵ', null, null, null, null, true, 'は', true),
  new KanaSet(null, null, null, 'ㇶ', null, null, null, null, true, 'ひ', true),
  new KanaSet(null, null, null, 'ㇷ', null, null, null, null, true, 'ふ', true),
  new KanaSet(null, null, null, 'ㇸ', null, null, null, null, true, 'へ', true),
  new KanaSet(null, null, null, 'ㇹ', null, null, null, null, true, 'ほ', true),
  new KanaSet(null, null, null, 'ㇺ', null, null, null, null, true, 'む', true),
  new KanaSet('ゃ', null, null, 'ャ', null, null, 'ｬ', null, true, 'や', false),
  new KanaSet('ゅ', null, null, 'ュ', null, null, 'ｭ', null, true, 'ゆ', false),
  new KanaSet('ょ', null, null, 'ョ', null, null, 'ｮ', null, true, 'よ', false),
  new KanaSet(null, null, null, 'ㇻ', null, null, null, null, true, 'ら', true),
  new KanaSet(null, null, null, 'ㇼ', null, null, null, null, true, 'り', true),
  new KanaSet(null, null, null, 'ㇽ', null, null, null, null, true, 'る', true),
  new KanaSet(null, null, null, 'ㇾ', null, null, null, null, true, 'れ', true),
  new KanaSet(null, null, null, 'ㇿ', null, null, null, null, true, 'ろ', true),
  new KanaSet(null, null, null, 'ヮ', null, null, null, null, true, 'わ', true),
  new KanaSet(
    '。',
    null,
    null,
    '。',
    null,
    null,
    '｡',
    null,
    false,
    null,
    false,
  ),
  new KanaSet(
    '、',
    null,
    null,
    '、',
    null,
    null,
    '､',
    null,
    false,
    null,
    false,
  ),
  new KanaSet(
    'ー',
    null,
    null,
    'ー',
    null,
    null,
    'ｰ',
    null,
    false,
    null,
    false,
  ),
  new KanaSet(
    '「',
    null,
    null,
    '「',
    null,
    null,
    '｢',
    null,
    false,
    null,
    false,
  ),
  new KanaSet(
    '」',
    null,
    null,
    '」',
    null,
    null,
    '｣',
    null,
    false,
    null,
    false,
  ),
  new KanaSet(
    '・',
    null,
    null,
    '・',
    null,
    null,
    '･',
    null,
    false,
    null,
    false,
  ),
]

class KanaEntry {
  constructor(kset, ktype, dtype) {
    this.kanaSet = kset

    this.kanaType = ktype

    this.dakuType = dtype
  }

  convert(ktype = KanaType.NA, dtype = DakuType.NA) {
    if (!ktype) ktype = this.kanaType
    if (!dtype) dtype = this.dakuType
    return this.kanaSet.encode(ktype, dtype)
  }
}

const KANA_TABLE = (function () {
  var dict = {}
  for (var i = 0; i < KANA_SET_LIST.length; i++) {
    var kset = KANA_SET_LIST[i]
    if (kset.hiraPlain)
      dict[kset.hiraPlain] = new KanaEntry(kset, KanaType.HIRA, DakuType.PLAIN)
    if (kset.hiraDaku)
      dict[kset.hiraDaku] = new KanaEntry(kset, KanaType.HIRA, DakuType.DAKU)
    if (kset.hiraHandaku)
      dict[kset.hiraHandaku] = new KanaEntry(
        kset,
        KanaType.HIRA,
        DakuType.HANDAKU,
      )
    if (kset.kataPlain)
      dict[kset.kataPlain] = new KanaEntry(kset, KanaType.KATA, DakuType.PLAIN)
    if (kset.kataDaku)
      dict[kset.kataDaku] = new KanaEntry(kset, KanaType.KATA, DakuType.DAKU)
    if (kset.kataHandaku)
      dict[kset.kataHandaku] = new KanaEntry(
        kset,
        KanaType.KATA,
        DakuType.HANDAKU,
      )
    if (kset.narrow)
      dict[kset.narrow] = new KanaEntry(kset, KanaType.NARROW, DakuType.PLAIN)
    if (kset.circled)
      dict[kset.circled] = new KanaEntry(kset, KanaType.CIRCLED, DakuType.PLAIN)
  }
  return dict
})()

var fontList = []

class Style {
  constructor(name, zwnjRequired = false) {
    this.name = name

    this.buttonLabel = ''

    this.zwnjRequired = zwnjRequired

    this.encodeTable = {}

    this.decodeTable = {}

    this.combinations = {}
  }

  map(normalPattern, styledChar) {
    var normalCodeBase = normalPattern.charCodeAt(0)
    var length = 1
    if (normalPattern.length >= 2) {
      length = normalPattern.charCodeAt(1) + 1 - normalCodeBase
    }

    var styledCodeBase
    if (typeof styledChar == 'number') {
      styledCodeBase = styledChar
    } else {
      styledCodeBase = Number.parseInt(styledChar)
    }

    for (var i = 0; i < length; i++) {
      this.encodeTable[normalCodeBase + i] = styledCodeBase + i
      this.decodeTable[styledCodeBase + i] = normalCodeBase + i
    }
    return this
  }

  combination(comb, styledChar) {
    var styledCode
    if (typeof styledChar == 'number') {
      styledCode = styledChar
    } else {
      styledCode = Number.parseInt(styledChar)
    }

    this.combinations[comb] = styledCode

    return this
  }

  setLabel(str = null) {
    if (!str) {
      str = ''
      if (this.encodeTable['A'.charCodeAt(0)]) str += 'AB'
      if (this.encodeTable['a'.charCodeAt(0)]) str += 'ab'
      if (this.encodeTable['αβ'.charCodeAt(0)]) str += 'αβ'
      if (this.encodeTable['1'.charCodeAt(0)]) str += '12'
      if (this.encodeTable['あ'.charCodeAt(0)]) str += 'あ'
      if (this.encodeTable['ア'.charCodeAt(0)]) str += 'ア'
      if (this.encodeTable['一'.charCodeAt(0)]) str += '一'
    }
    this.buttonLabel = new StyledString(str, this).toString()
    return this
  }

  encode(normalCode) {
    var styledCode = this.encodeTable[normalCode]
    if (styledCode) {
      return styledCode
    } else {
      return normalCode
    }
  }
}

class StyledChar {
  constructor(normalCode, style = null) {
    this.normalCode = normalCode

    this.style = style
  }

  clone() {
    return new StyledChar(this.normalCode, this.style)
  }

  normalChar() {
    return String.fromCharCode(this.normalCode)
  }

  isUpperCase() {
    return 0x41 <= this.normalCode && this.normalCode <= 0x5a
  }
  isLowerCase() {
    return 0x61 <= this.normalCode && this.normalCode <= 0x7a
  }
  isAlplabet() {
    return this.isUpperCase() || this.isLowerCase()
  }

  toLowerCase() {
    if (this.isUpperCase()) this.normalCode += 0x20
    return this
  }

  toUpperCase() {
    if (this.isLowerCase()) this.normalCode -= 0x20
    return this
  }

  encode() {
    if (this.style) {
      return this.style.encode(this.normalCode)
    } else {
      return this.normalCode
    }
  }

  toString() {
    var str = '{code:0x' + this.normalCode.toString(16)
    if (this.style == null) {
      str += ', ' + this.style
    }
    str += '}'
    return str
  }
}

class StyledString {
  constructor(styledStr, style = null) {
    var utf32Array = convertStringToUtf32CodeArray(styledStr)
    var buff = []
    for (var i = 0; i < utf32Array.length; i++) {
      if (utf32Array[i] == UTF16_ZERO_WIDTH_NON_JOINER) continue
      var styledChar = decodeStyledChar(utf32Array[i])
      buff.push(styledChar)
    }

    this.chars = buff

    if (style) this.setStyle(style)
  }

  clone() {
    return new StyledString(this.toString())
  }

  setStyle(style, ignore_case = false) {
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]
      if (style == null) {
        c.style = null
      } else if (style.encodeTable[c.normalCode]) {
        c.style = style
      } else if (ignore_case) {
        var toggledChar = toggleCase(c.normalCode)
        if (style.encodeTable[toggledChar]) {
          c.normalCode = toggledChar
          c.style = style
        }
      }
    }
  }

  toLowerCase() {
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]
      if (c.isAlplabet()) c.toLowerCase()
    }
  }

  toUpperCase() {
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]
      if (c.isAlplabet()) c.toUpperCase()
    }
  }

  toCamelCase() {
    var lastIsAlpha = false
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]
      var cIsAlpha = c.isAlplabet()
      if (cIsAlpha) {
        if (lastIsAlpha) {
          c.toLowerCase()
        } else {
          c.toUpperCase()
        }
      }
      lastIsAlpha = cIsAlpha
    }
  }

  indexOf(key, start = 0) {
    var rawStr = this.clone()
    rawStr.setStyle(null)
    return this.clone().toString().indexOf(key, start)
  }

  replace(a, b, newStyle = null) {
    var i = 0
    var last_i = 0
    while (i < this.chars.length) {
      var apos = this.indexOf(a, i)
      if (apos < i) return
      if (!newStyle) newStyle = this.chars[apos].style

      var before = this.chars.slice(0, apos)
      var after = this.chars.slice(apos + a.length)
      for (var j = 0; j < b.length; j++) {
        before.push(new StyledChar(b.charCodeAt(j), newStyle))
      }
      this.chars = before.concat(after)
      i = apos + b.length
    }
  }

  toHiragana() {
    this.convertKana(KanaType.HIRA)
  }
  toKatakana() {
    this.convertKana(KanaType.KATA)
  }
  toNarrowKatakana() {
    this.convertKana(KanaType.NARROW)
  }
  toCircledKatakana() {
    this.convertKana(KanaType.CIRCLED)
  }
  toNoDakuon() {
    this.convertKana(KanaType.NA, DakuType.PLAIN)
  }
  toDakuon() {
    this.convertKana(KanaType.NA, DakuType.DAKU)
  }
  toHandakuon() {
    this.convertKana(KanaType.NA, DakuType.HANDAKU)
  }
  toLargeKana() {
    this.convertKana(KanaType.NA, DakuType.NA, KanaSize.LARGE)
  }
  toSmallKana() {
    this.convertKana(KanaType.NA, DakuType.NA, KanaSize.SMALL)
  }

  convertKana(
    newKanaType = KanaType.NA,
    newDakuType = DakuType.NA,
    newKanaSize = KanaSize.NA,
  ) {
    var newChars = []
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]

      if (!KANA_TABLE[c.normalChar()]) {
        newChars.push(c)
        continue
      }

      var kana = KANA_TABLE[c.normalChar()]
      var ktype = kana.kanaType
      var dtype = kana.dakuType

      while (i + 1 < this.chars.length) {
        var cNext = this.chars[i + 1].normalChar()
        if (cNext == '゛' || cNext == 'ﾞ' || cNext == '\u3099') {
          dtype = DakuType.DAKU
        } else if (cNext == '゜' || cNext == 'ﾟ' || cNext == '\u309a') {
          dtype = DakuType.HANDAKU
        } else {
          break
        }
        i += 1
      }

      if (newKanaType != KanaType.NA) ktype = newKanaType
      if (newDakuType != DakuType.NA) dtype = newDakuType

      if (newKanaSize == KanaSize.LARGE) {
        if (kana.kanaSet.isSmallKana && kana.kanaSet.sizePair) {
          kana = KANA_TABLE[kana.kanaSet.sizePair]
        }
      } else if (newKanaSize == KanaSize.SMALL) {
        if (!kana.kanaSet.isSmallKana && kana.kanaSet.sizePair) {
          kana = KANA_TABLE[kana.kanaSet.sizePair]
        }
      }

      var newKanaStr = kana.convert(ktype, dtype)
      for (var j = 0; j < newKanaStr.length; j++) {
        newChars.push(new StyledChar(newKanaStr.charCodeAt(j), null))
      }
    }
    this.chars = newChars
  }

  toString() {
    var buff = []
    var lastZWNJRequired = false
    for (var i = 0; i < this.chars.length; i++) {
      var c = this.chars[i]
      var zwnjRequired = c.style != null && c.style.zwnjRequired

      if (lastZWNJRequired && zwnjRequired) {
        buff.push(UTF16_ZERO_WIDTH_NON_JOINER) // ZWNJ (Zero Width Non-Joiner)
      }

      buff.push(c.encode())

      lastZWNJRequired = zwnjRequired
    }
    return convertUtf32CodeArrayToString(buff)
  }
}

function toggleCase(c) {
  if (0x41 <= c && c <= 0x5a) {
    return c + 0x20
  } else if (0x61 <= c && c <= 0x7a) {
    return c - 0x20
  } else {
    return c
  }
}

function decodeStyledChar(styledCode) {
  for (var i = 0; i < fontList.length; i++) {
    var style = fontList[i]
    var normalCode = style.decodeTable[styledCode]
    if (normalCode) {
      return new StyledChar(normalCode, style)
    }
  }
  return new StyledChar(styledCode, null)
}

function convertStringToUtf32CodeArray(codeStr) {
  var srcLen = codeStr.length
  var index = 0
  var buff = []
  while (index < srcLen) {
    var currCode = codeStr.charCodeAt(index)
    var nextCode = 0
    if (index < codeStr.length - 1) {
      nextCode = codeStr.charCodeAt(index + 1)
    }

    if (
      UTF16_SURROGATE_PAIR_HIGH_BASE <= currCode &&
      currCode <= UTF16_SURROGATE_PAIR_HIGH_END
    ) {
      var h = currCode - UTF16_SURROGATE_PAIR_HIGH_BASE
      var l = nextCode - UTF16_SURROGATE_PAIR_LOW_BASE
      buff.push(0x10000 | (h << 10) | l)
      index += 2
    } else if ((currCode & 0xfc00) == 0xd800 && (nextCode & 0xfc00) == 0xdc00) {
      var h = currCode & 0x3c0 // ------hh hh------ (4 bits)
      var m = currCode & 0x03f // -------- --mmmmmm (6 bits)
      var l = nextCode & 0x03f // ------ll llllllll (10 bits)

      h = (h + 0x40) & 0x7c0

      buff.push((h << 10) | (m << 10) | l)
      index += 2
    } else {
      buff.push(currCode)
      index += 1
    }
  }
  return buff
}

function convertUtf32CodeArrayToString(utf32CodeArray) {
  var buff = ''

  for (var i = 0; i < utf32CodeArray.length; i++) {
    var utf32Code = utf32CodeArray[i]

    if (0x10000 <= utf32Code) {
      var h = utf32Code & 0x1f0000 // ---HHHHH -------- -------- (5 bits)
      var m = utf32Code & 0x00fc00 // -------- mmmmmm-- -------- (6 bits)
      var l = utf32Code & 0x0003ff // -------- ------ll llllllll (10 bits)

      h = (h - 0x10000) & 0x0f0000

      var c0 = 0xd800 | (h >> 10) | (m >> 10) // 110110hh hhmmmmmm
      var c1 = 0xdc00 | l // 110111ll llllllll

      buff += String.fromCharCode(c0, c1)
    } else if (
      UTF32_SURROGATE_PAIR_BASE <= utf32Code &&
      utf32Code <= UTF32_SURROGATE_PAIR_END
    ) {
      buff += '?'
    } else {
      buff += String.fromCharCode(utf32Code)
    }
  }

  return buff
}

fontList.push(
  new Style('Bold')
    .map('AZ', 0x1d400)
    .map('az', 0x1d400 + 26)
    .map('09', 0x1d7ce)
    .map('Α', 0x1d6a8)
    .map('Β', 0x1d6a9)
    .map('Γ', 0x1d6aa)
    .map('Δ', 0x1d6ab)
    .map('Ε', 0x1d6ac)
    .map('Ζ', 0x1d6ad)
    .map('Η', 0x1d6ae)
    .map('Θ', 0x1d6af)
    .map('Ι', 0x1d6b0)
    .map('Κ', 0x1d6b1)
    .map('Λ', 0x1d6b2)
    .map('Μ', 0x1d6b3)
    .map('Ν', 0x1d6b4)
    .map('Ξ', 0x1d6b5)
    .map('Ο', 0x1d6b6)
    .map('Π', 0x1d6b7)
    .map('Ρ', 0x1d6b8)
    .map('Σ', 0x1d6ba)
    .map('Τ', 0x1d6bb)
    .map('Υ', 0x1d6bc)
    .map('Φ', 0x1d6bd)
    .map('Χ', 0x1d6be)
    .map('Ψ', 0x1d6bf)
    .map('Ω', 0x1d6c0)
    .map('α', 0x1d6c2)
    .map('β', 0x1d6c3)
    .map('γ', 0x1d6c4)
    .map('δ', 0x1d6c5)
    .map('ε', 0x1d6c6)
    .map('ζ', 0x1d6c7)
    .map('η', 0x1d6c8)
    .map('θ', 0x1d6c9)
    .map('ι', 0x1d6ca)
    .map('κ', 0x1d6cb)
    .map('λ', 0x1d6cc)
    .map('μ', 0x1d6cd)
    .map('ν', 0x1d6ce)
    .map('ξ', 0x1d6cf)
    .map('ο', 0x1d6d0)
    .map('π', 0x1d6d1)
    .map('ρ', 0x1d6d2)
    .map('σ', 0x1d6d4)
    .map('ς', 0x1d6d3)
    .map('τ', 0x1d6d5)
    .map('υ', 0x1d6d6)
    .map('φ', 0x1d6d7)
    .map('χ', 0x1d6d8)
    .map('ψ', 0x1d6d9)
    .map('ω', 0x1d6da)
    .map('∇', 0x1d6c1)
    .setLabel(),
)

fontList.push(
  new Style('Italic')
    .map('AZ', 0x1d434)
    .map('az', 0x1d434 + 26)
    .map('h', 0x210e)
    .map('Α', 0x1d6e2)
    .map('Β', 0x1d6e3)
    .map('Γ', 0x1d6e4)
    .map('Δ', 0x1d6e5)
    .map('Ε', 0x1d6e6)
    .map('Ζ', 0x1d6e7)
    .map('Η', 0x1d6e8)
    .map('Θ', 0x1d6e9)
    .map('Ι', 0x1d6ea)
    .map('Κ', 0x1d6eb)
    .map('Λ', 0x1d6ec)
    .map('Μ', 0x1d6ed)
    .map('Ν', 0x1d6ee)
    .map('Ξ', 0x1d6ef)
    .map('Ο', 0x1d6f0)
    .map('Π', 0x1d6f1)
    .map('Ρ', 0x1d6f2)
    .map('Σ', 0x1d6f4)
    .map('Τ', 0x1d6f5)
    .map('Υ', 0x1d6f6)
    .map('Φ', 0x1d6f7)
    .map('Χ', 0x1d6f8)
    .map('Ψ', 0x1d6f9)
    .map('Ω', 0x1d6fa)
    .map('α', 0x1d6fc)
    .map('β', 0x1d6fd)
    .map('γ', 0x1d6fe)
    .map('δ', 0x1d6ff)
    .map('ε', 0x1d700)
    .map('ζ', 0x1d701)
    .map('η', 0x1d702)
    .map('θ', 0x1d703)
    .map('ι', 0x1d704)
    .map('κ', 0x1d705)
    .map('λ', 0x1d706)
    .map('μ', 0x1d707)
    .map('ν', 0x1d708)
    .map('ξ', 0x1d709)
    .map('ο', 0x1d70a)
    .map('π', 0x1d70b)
    .map('ρ', 0x1d70c)
    .map('σ', 0x1d70e)
    .map('ς', 0x1d70d)
    .map('τ', 0x1d70f)
    .map('υ', 0x1d710)
    .map('φ', 0x1d711)
    .map('χ', 0x1d712)
    .map('ψ', 0x1d713)
    .map('ω', 0x1d714)
    .map('∇', 0x1d6fb)
    .setLabel(),
)

fontList.push(
  new Style('BoldItalic')
    .map('AZ', 0x1d468)
    .map('az', 0x1d468 + 26)
    .map('Α', 0x1d71c)
    .map('Β', 0x1d71d)
    .map('Γ', 0x1d71e)
    .map('Δ', 0x1d71f)
    .map('Ε', 0x1d720)
    .map('Ζ', 0x1d721)
    .map('Η', 0x1d722)
    .map('Θ', 0x1d723)
    .map('Ι', 0x1d724)
    .map('Κ', 0x1d725)
    .map('Λ', 0x1d726)
    .map('Μ', 0x1d727)
    .map('Ν', 0x1d728)
    .map('Ξ', 0x1d729)
    .map('Ο', 0x1d72a)
    .map('Π', 0x1d72b)
    .map('Ρ', 0x1d72c)
    .map('Σ', 0x1d72e)
    .map('Τ', 0x1d72f)
    .map('Υ', 0x1d730)
    .map('Φ', 0x1d731)
    .map('Χ', 0x1d732)
    .map('Ψ', 0x1d733)
    .map('Ω', 0x1d734)
    .map('α', 0x1d736)
    .map('β', 0x1d737)
    .map('γ', 0x1d738)
    .map('δ', 0x1d739)
    .map('ε', 0x1d73a)
    .map('ζ', 0x1d73b)
    .map('η', 0x1d73c)
    .map('θ', 0x1d73d)
    .map('ι', 0x1d73e)
    .map('κ', 0x1d73f)
    .map('λ', 0x1d740)
    .map('μ', 0x1d741)
    .map('ν', 0x1d742)
    .map('ξ', 0x1d743)
    .map('ο', 0x1d744)
    .map('π', 0x1d745)
    .map('ρ', 0x1d746)
    .map('σ', 0x1d748)
    .map('ς', 0x1d747)
    .map('τ', 0x1d749)
    .map('υ', 0x1d74a)
    .map('φ', 0x1d74b)
    .map('χ', 0x1d74c)
    .map('ψ', 0x1d74d)
    .map('ω', 0x1d74e)
    .map('∇', 0x1d735)
    .setLabel(),
)

fontList.push(
  new Style('SansSerif')
    .map('AZ', 0x1d5a0)
    .map('az', 0x1d5a0 + 26)
    .map('09', 0x1d7e2)
    .setLabel(),
)

fontList.push(
  new Style('SansSerifBold')
    .map('AZ', 0x1d5d4)
    .map('az', 0x1d5d4 + 26)
    .map('09', 0x1d7ec)
    .map('Α', 0x1d756)
    .map('Β', 0x1d757)
    .map('Γ', 0x1d758)
    .map('Δ', 0x1d759)
    .map('Ε', 0x1d75a)
    .map('Ζ', 0x1d75b)
    .map('Η', 0x1d75c)
    .map('Θ', 0x1d75d)
    .map('Ι', 0x1d75e)
    .map('Κ', 0x1d75f)
    .map('Λ', 0x1d760)
    .map('Μ', 0x1d761)
    .map('Ν', 0x1d762)
    .map('Ξ', 0x1d763)
    .map('Ο', 0x1d764)
    .map('Π', 0x1d765)
    .map('Ρ', 0x1d766)
    .map('Σ', 0x1d768)
    .map('Τ', 0x1d769)
    .map('Υ', 0x1d76a)
    .map('Φ', 0x1d76b)
    .map('Χ', 0x1d76c)
    .map('Ψ', 0x1d76d)
    .map('Ω', 0x1d76e)
    .map('α', 0x1d770)
    .map('β', 0x1d771)
    .map('γ', 0x1d772)
    .map('δ', 0x1d773)
    .map('ε', 0x1d774)
    .map('ζ', 0x1d775)
    .map('η', 0x1d776)
    .map('θ', 0x1d777)
    .map('ι', 0x1d778)
    .map('κ', 0x1d779)
    .map('λ', 0x1d77a)
    .map('μ', 0x1d77b)
    .map('ν', 0x1d77c)
    .map('ξ', 0x1d77d)
    .map('ο', 0x1d77e)
    .map('π', 0x1d77f)
    .map('ρ', 0x1d780)
    .map('σ', 0x1d782)
    .map('ς', 0x1d781)
    .map('τ', 0x1d783)
    .map('υ', 0x1d784)
    .map('φ', 0x1d785)
    .map('χ', 0x1d786)
    .map('ψ', 0x1d787)
    .map('ω', 0x1d788)
    .map('∇', 0x1d76f)
    .setLabel(),
)

fontList.push(
  new Style('SansSerifItalic')
    .map('AZ', 0x1d608)
    .map('az', 0x1d608 + 26)
    .setLabel(),
)

fontList.push(
  new Style('SansSerifBoldItalic')
    .map('AZ', 0x1d63c)
    .map('az', 0x1d63c + 26)
    .map('Α', 0x1d790)
    .map('Β', 0x1d791)
    .map('Γ', 0x1d792)
    .map('Δ', 0x1d793)
    .map('Ε', 0x1d794)
    .map('Ζ', 0x1d795)
    .map('Η', 0x1d796)
    .map('Θ', 0x1d797)
    .map('Ι', 0x1d798)
    .map('Κ', 0x1d799)
    .map('Λ', 0x1d79a)
    .map('Μ', 0x1d79b)
    .map('Ν', 0x1d79c)
    .map('Ξ', 0x1d79d)
    .map('Ο', 0x1d79e)
    .map('Π', 0x1d79f)
    .map('Ρ', 0x1d7a0)
    .map('Σ', 0x1d7a2)
    .map('Τ', 0x1d7a3)
    .map('Υ', 0x1d7a4)
    .map('Φ', 0x1d7a5)
    .map('Χ', 0x1d7a6)
    .map('Ψ', 0x1d7a7)
    .map('Ω', 0x1d7a8)
    .map('α', 0x1d7aa)
    .map('β', 0x1d7ab)
    .map('γ', 0x1d7ac)
    .map('δ', 0x1d7ad)
    .map('ε', 0x1d7ae)
    .map('ζ', 0x1d7af)
    .map('η', 0x1d7b0)
    .map('θ', 0x1d7b1)
    .map('ι', 0x1d7b2)
    .map('κ', 0x1d7b3)
    .map('λ', 0x1d7b4)
    .map('μ', 0x1d7b5)
    .map('ν', 0x1d7b6)
    .map('ξ', 0x1d7b7)
    .map('ο', 0x1d7b8)
    .map('π', 0x1d7b9)
    .map('ρ', 0x1d7ba)
    .map('σ', 0x1d7bc)
    .map('ς', 0x1d7bb)
    .map('τ', 0x1d7bd)
    .map('υ', 0x1d7be)
    .map('φ', 0x1d7bf)
    .map('χ', 0x1d7c0)
    .map('ψ', 0x1d7c1)
    .map('ω', 0x1d7c2)
    .map('∇', 0x1d7a9)
    .setLabel(),
)

fontList.push(
  new Style('Script')
    .map('AZ', 0x1d49c)
    .map('az', 0x1d49c + 26)
    .map('B', 0x212c)
    .map('E', 0x2130)
    .map('F', 0x2131)
    .map('H', 0x210b)
    .map('I', 0x2110)
    .map('L', 0x2112)
    .map('M', 0x2133)
    .map('R', 0x211b)
    .map('e', 0x212f)
    .map('g', 0x210a)
    .map('o', 0x2134)
    .setLabel(),
)

fontList.push(
  new Style('BoldScript')
    .map('AZ', 0x1d4d0)
    .map('az', 0x1d4d0 + 26)
    .setLabel(),
)

fontList.push(
  new Style('Monospace')
    .map('AZ', 0x1d670)
    .map('az', 0x1d670 + 26)
    .map('09', 0x1d7f6)
    .setLabel(),
)

fontList.push(
  new Style('Fraktor')
    .map('AZ', 0x1d504)
    .map('az', 0x1d504 + 26)
    .map('C', 0x212d)
    .map('H', 0x210c)
    .map('I', 0x2111)
    .map('R', 0x211c)
    .map('Z', 0x2128)
    .setLabel(),
)

fontList.push(
  new Style('DoubleStruck')
    .map('AZ', 0x1d538)
    .map('az', 0x1d538 + 26)
    .map('09', 0x1d7d8)
    .map('C', 0x2102)
    .map('H', 0x210d)
    .map('N', 0x2115)
    .map('P', 0x2119)
    .map('Q', 0x211a)
    .map('R', 0x211d)
    .map('Z', 0x2124)
    .map('Γ', 0x213e)
    .map('γ', 0x213d)
    .map('Π', 0x213f)
    .map('π', 0x213c)
    .map('Σ', 0x2140)
    .setLabel(),
)

fontList.push(
  new Style('Wide')
    .map('!~', 0xff01)
    .map('`', 0x2018)
    .map('"', 0x201d)
    .map(' ', 0x3000)
    .setLabel(),
)

fontList.push(
  new Style('Circled')
    .map('AZ', 0x24b6)
    .map('az', 0x24b6 + 26)
    .map('0', 0x24ea)
    .map('19', 0x2460)
    .map('ア', 0x32d0)
    .map('イ', 0x32d1)
    .map('ウ', 0x32d2)
    .map('エ', 0x32d3)
    .map('オ', 0x32d4)
    .map('カ', 0x32d5)
    .map('キ', 0x32d6)
    .map('ク', 0x32d7)
    .map('ケ', 0x32d8)
    .map('コ', 0x32d9)
    .map('サ', 0x32da)
    .map('シ', 0x32db)
    .map('ス', 0x32dc)
    .map('セ', 0x32dd)
    .map('ソ', 0x32de)
    .map('タ', 0x32df)
    .map('チ', 0x32e0)
    .map('ツ', 0x32e1)
    .map('テ', 0x32e2)
    .map('ト', 0x32e3)
    .map('ナ', 0x32e4)
    .map('ニ', 0x32e5)
    .map('ヌ', 0x32e6)
    .map('ネ', 0x32e7)
    .map('ノ', 0x32e8)
    .map('ハ', 0x32e9)
    .map('ヒ', 0x32ea)
    .map('フ', 0x32eb)
    .map('ヘ', 0x32ec)
    .map('ホ', 0x32ed)
    .map('マ', 0x32ee)
    .map('ミ', 0x32ef)
    .map('ム', 0x32f0)
    .map('メ', 0x32f1)
    .map('モ', 0x32f2)
    .map('ヤ', 0x32f3)
    .map('ユ', 0x32f4)
    .map('ヨ', 0x32f5)
    .map('ラ', 0x32f6)
    .map('リ', 0x32f7)
    .map('ル', 0x32f8)
    .map('レ', 0x32f9)
    .map('ロ', 0x32fa)
    .map('ワ', 0x32fb)
    .map('ヰ', 0x32fc)
    .map('ヲ', 0x32fe)
    .map('ヱ', 0x32fd)
    .map('一', 0x3280)
    .map('二', 0x3281)
    .map('三', 0x3282)
    .map('四', 0x3283)
    .map('五', 0x3284)
    .map('六', 0x3285)
    .map('七', 0x3286)
    .map('八', 0x3287)
    .map('九', 0x3288)
    .map('十', 0x3289)
    .setLabel(),
)

fontList.push(
  new Style('BlackCircled')
    .map('AZ', 0x1f150)
    .map('0', 0x24ff)
    .map('19', 0x2776)
    .setLabel(),
)

fontList.push(new Style('DoubleCircledDigit').map('19', 0x24f5).setLabel())

fontList.push(
  new Style('Parenthesized')
    .map('AZ', 0x1f110)
    .map('az', 0x249c)
    .map('19', 0x2474)
    .setLabel(),
)

fontList.push(new Style('BlackSquared').map('AZ', 0x1f170).setLabel())

fontList.push(new Style('Squared').map('AZ', 0x1f130).setLabel())

fontList.push(
  new Style('RegionalIndicator', true).map('AZ', 0x1f1e6).setLabel(),
)

fontList.push(
  new Style('SuperScript')
    .map('A', 0x1d2c)
    .map('B', 0x1d2e)
    .map('DE', 0x1d30)
    .map('GN', 0x1d33)
    .map('O', 0x1d3c)
    .map('P', 0x1d3e)
    .map('R', 0x1d3f)
    .map('TU', 0x1d40)
    .map('V', 0x2c7d)
    .map('W', 0x1d42)
    .map('a', 0x1d43)
    .map('b', 0x1d47)
    .map('c', 0x1d9c)
    .map('de', 0x1d48)
    .map('f', 0x1da0)
    .map('g', 0x1d4d)
    .map('h', 0x2b0)
    .map('i', 0x2071)
    .map('j', 0x2b2)
    .map('k', 0x1d4f)
    .map('l', 0x2e1)
    .map('m', 0x1d50)
    .map('n', 0x207f)
    .map('o', 0x1d52)
    .map('p', 0x1d56)
    .map('r', 0x2b3)
    .map('s', 0x2e2)
    .map('tu', 0x1d57)
    .map('v', 0x1d5b)
    .map('w', 0x2b7)
    .map('x', 0x2e3)
    .map('y', 0x2b8)
    .map('z', 0x1dbb)
    .map('0', 0x2070)
    .map('1', 0xb9)
    .map('23', 0xb2)
    .map('49', 0x2074)
    .map('+', 0x207a)
    .map('-', 0x207b)
    .map('=', 0x207c)
    .map('()', 0x207d)
    .map('α', 0x1d45)
    .map('Æ', 0x1d2d)
    .map('æ', 0x1d46)
    .map('β', 0x1d5d)
    .map('γδ', 0x1d5e)
    .map('ε', 0x1d4b)
    .map('Φ', 0x1db2)
    .map('φ', 0x1d60)
    .map('∫', 0x1db4)
    .map('θ', 0x1dbf)
    .setLabel('\u2b1aabc'),
)

fontList.push(
  new Style('SubScript')
    .map('a', 0x2090)
    .map('e', 0x2091)
    .map('h', 0x2095)
    .map('i', 0x1d62)
    .map('j', 0x2c7c)
    .map('kn', 0x2096)
    .map('o', 0x2092)
    .map('p', 0x209a)
    .map('r', 0x1d63)
    .map('st', 0x209b)
    .map('uv', 0x1d64)
    .map('x', 0x2093)
    .map('09', 0x2080)
    .map('+', 0x208a)
    .map('-', 0x208b)
    .map('=', 0x208c)
    .map('()', 0x208d)
    .map('βγ', 0x1d66)
    .map('ρ', 0x1d68)
    .map('φϖ', 0x1d69)
    .setLabel('\u2b1a123'),
)

export function applyFontStyle(text, styleName) {
  const style = fontList.find(s => s.name === styleName)
  if (!style) {
    console.log('指定されたスタイルが見つかりません。')
    return text
  }

  const styledString = new StyledString(text)
  styledString.setStyle(style)

  return styledString.toString()
}
