import {isWeb} from '#/platform/detection'
import {useEffect} from 'react'

const useGlobalKeyDown = (callback: () => void) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'n') {
        callback()
      }
    }
    if (isWeb) {
      window.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      if (isWeb) {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [callback])
}

export default useGlobalKeyDown
